import MessageBag from "@/services/message-bag";
import { AxiosError } from "axios";
import capitalize from "@/filters/capitalize";
import { StatusCodes } from "http-status-codes";

export class ValidationErrors extends MessageBag<string, string> {}

class ErrorHandler {
    getValidationErrors(error: AxiosError): ValidationErrors {
        const errors = new ValidationErrors();
        if (
            error === null ||
            typeof error.response?.data?.data !== "object" ||
            error.response?.data?.data === null ||
            error.response?.status !== StatusCodes.UNPROCESSABLE_ENTITY
        ) {
            return errors;
        }

        Object.keys(error.response.data.data).forEach((key: string) => {
            errors.addMany(
                key,
                this.sanitize(key, error.response?.data.data[key])
            );
        });

        return errors;
    }

    sanitize(key: string, values: Array<string>): Array<string> {
        return values.map((value: string) => {
            return capitalize(
                value
                    .split(key)
                    .join(key.replace("_", " "))
                    .split("_")
                    .join(" ")
                    .split("-")
                    .join(" ")
                    .split(" char")
                    .join(" character")
                    .split(" field ")
                    .join(" ")
            );
        });
    }
}

export default new ErrorHandler();
