export interface SerializedReferral {
    url: string;
    click_count: number;
    total_amount: number;
    order_count: number;
    phone_number: string;
    payout_amount: number;
    discount_amount: number;
}

export default class Referral {
    private readonly _url: string;
    private readonly _clickCount: number;
    private readonly _totalAmount: number;
    private readonly _orderCount: number;
    private readonly _phoneNumber: string;
    private readonly _payoutAmount: number;
    private readonly _discountAmount: number;

    constructor(referral: SerializedReferral) {
        this._url = referral.url;
        this._clickCount = referral.click_count;
        this._totalAmount = referral.total_amount;
        this._orderCount = referral.order_count;
        this._phoneNumber = referral.phone_number;
        this._payoutAmount = referral.payout_amount;
        this._discountAmount = referral.discount_amount;
    }

    get discountAmount(): number {
        return this._discountAmount;
    }

    get payoutAmount(): number {
        return this._payoutAmount;
    }

    get url(): string {
        return this._url;
    }

    get clickCount(): number {
        return this._clickCount;
    }

    get totalAmount(): number {
        return this._totalAmount;
    }

    get orderCount(): number {
        return this._orderCount;
    }

    get phoneNumber(): string {
        return this._phoneNumber;
    }
}
